// import * as firebase from "firebase";
// import "@firebase/auth";
// import "@firebase/firestore";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBmUxi3SGf6ViTnXNRp7W8hF92xh9lqs2E",
  authDomain: "lighthouse-c2cd5.firebaseapp.com",
  projectId: "lighthouse-c2cd5",
  storageBucket: "lighthouse-c2cd5.appspot.com",
  messagingSenderId: "933705065850",
  appId: "1:933705065850:web:f6cefc9bbf7a03e3b762c3",
  measurementId: "G-HP7XTMJ3RL"
};

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  export { firebase };