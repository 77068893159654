import React, {useState, useEffect} from "react";
import Banner from "./Banner"
import Spacer from "react-spacer";
import "../css/AboutUs.css";

const AboutUs = () => {
    return (
        <div class="center">
            <Banner
                heading="About Us"
            />
            <div class="subheading about-align">
                Our Mission
            </div>
            <div class="about ">
                NFTs are an up-and-coming technology that has the potential to change the world. 
                But NFTs are a nascent technology, and breaking into the space can be intimidating and difficult.
                Our goal at Lighthouse is to demystify NFT projects and help people understand NFTs better.
                We will post educational content and evaluate hot NFT projects, breaking down what aspects 
                you should be looking at and what to look for when investing in NFTs.
            </div>
            <Spacer height='30px' />
            {/* <div class="subheading about-align">
                Who We Are
            </div>
            <div class="about ">
                NFT Educate is run by a group of NFT enthusiasts. We believe in the technology as a pastime and in NFTs’ applications. Our writers' experience with NFTs range from casual to professional. Our technical writers have worked at companies like BirthVenue and PawnBlock. Most articles are written by NFT collectors. Our writers have one thing in common. They are all passionate about NFTs.
            </div> */}
            <Spacer height='50px' />
        </div>
    );
};

export default AboutUs;